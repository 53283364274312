export const fuboDetail = [
  {
    id: 1,
    defalut: require('@/assets/img/浮波系列素材/产品模特图/SBKH1.jpg'),
    active: require('@/assets/img/浮波系列素材/产品模特图/SBKH2.jpg'),
    series: '浮波系列',
    seriesName: '水波口红',
    zhName: '酵色「水波口红」',
    enName: 'Sand Ripple Matte Lip Rouge',
    intro: '柔雾哑光质地 奶油感红唇',
    price: '79',
    of: '3g',
    imgUrl: [
      { index: 1, url: require('@/assets/img/详情/水波口红素材/小图/4.jpg'), bigUrl: require('@/assets/img/详情/水波口红素材/大图/4.jpg') },
      { index: 2, url: require('@/assets/img/详情/水波口红素材/小图/2.jpg'), bigUrl: require('@/assets/img/详情/水波口红素材/大图/2.jpg') },
      { index: 3, url: require('@/assets/img/详情/水波口红素材/小图/3.jpg'), bigUrl: require('@/assets/img/详情/水波口红素材/大图/3.jpg') },
      { index: 3, url: require('@/assets/img/详情/水波口红素材/小图/1.jpg'), bigUrl: require('@/assets/img/详情/水波口红素材/大图/1.jpg') },
    ],
    color: [
      {
        active: '#823d3f',
        name: '#101 瓦砾回声',
        span1: '灰调陶土奶咖 丧甜超模',
        span2: ' ',
        color: require('@/assets/img/详情/水波口红素材/大图/01.jpg'),
      },
      {
        active: '#a2514d',
        name: '#102 赤茶冥想',
        span1: '土调红棕 慵懒气质',
        span2: ' ',
        color: require('@/assets/img/详情/水波口红素材/大图/02.jpg')
      },
      {
        active: '#be4347',
        name: '#103 杏桃冻干',
        span1: '粉雾感杏桃 甜美清爽',
        span2: ' ',
        color: require('@/assets/img/详情/水波口红素材/大图/03.jpg')
      },
      {
        active: '#a33e4a',
        name: '#104 暮色花市',
        span1: '烟熏紫豆沙 轻熟迷人',
        span2: ' ',
        color: require('@/assets/img/详情/水波口红素材/大图/04.jpg')
      },
      {
        active: '#994c38',
        name: '#105 日落苏打',
        span1: '复古黄棕调 油画质感',
        span2: ' ',
        color: require('@/assets/img/详情/水波口红素材/大图/05.jpg')
      },
      {
        active: '#cc2347',
        name: '#106 羞怯甜莓',
        span1: '粉紫莓果 冷调迷情',
        span2: ' ',
        color: require('@/assets/img/详情/水波口红素材/大图/06.jpg')
      },
      {
        active: '#7e0920',
        name: '#107 沉溺夜月',
        span1: '蓝调浆果 叛逆俏皮',
        span2: ' ',
        color: require('@/assets/img/详情/水波口红素材/大图/07.jpg')
      },
      {
        active: '#aa2929',
        name: '#108 持有天真',
        span1: '艳阳苹果红 活力满满',
        span2: ' ',
        color: require('@/assets/img/详情/水波口红素材/大图/08.jpg')
      }
    ]
  },
]